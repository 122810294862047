import { useEffect } from "react";
import { setEsiaData } from "../functions/function";
import { useSearchParams } from "react-router-dom";
import { ServerApi } from "../api/ServerApi";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function GetEsiaData() {
  const [searchParams, setSearchParams] = useSearchParams();
  const esiaCode = searchParams.get("code");
  const esiaError = searchParams.get("error");
  const serverApi = new ServerApi();
  const [cookies, setCookie] = useCookies([
    "operator_id",
    "user_id",
    "esia_url",
    "esia_token",
    "link_token",
  ]);
  const navigate = useNavigate();

  //Получение данных от ЕСИА.
  useEffect(() => {
    if (esiaCode) {
      setEsiaData(esiaCode).then((response) => {
        const esia_token = response.token;
        const esia_token_expire = response.timestamp.exp;
        let expires = new Date(Number(esia_token_expire) * 1000);
        console.log(expires);
        // setCookie('esia_token', esia_token, {path: 'https://app.idgos.ru/docs', expires: expires})
        const user_id = localStorage.getItem("user_id");
        const operator_id = localStorage.getItem("operator_id");
        serverApi
          .setUserToken(user_id, operator_id, esia_token, esia_token_expire)
          .then((response) => {
            if(response){
              const user_id = localStorage.getItem('user_id')
              const operator_id = localStorage.getItem('operator_id')
              const body = {
                action: 'checkNoSignedDocs',
                data: {
                  user_id: user_id,
                  operator_id: operator_id
                }
              }
              serverApi.sendPostRequest(body).then((response) => {
                console.log(response)
                if(response.status === 200) {
                  if(response.data.documents.length == 0) navigate('/signed')
                }
            })
          }
            navigate("/doc");
          });
      });
    }
    if (esiaError) {
      alert('К сожалению, возникла проблема при попытке загрузить данные. Пожалуйста, попробуйте повторно перейти по ссылке, которую мы отправили вам ранее на телефон.');
        navigate('/');
    }
  }, []);

  return (
    <div id="main-page-wrapper">
      <div className="justify-content-center" id="sign_loader">
        <div className="">
          <h4 className="text-center loader-text">Получение данных</h4>
          <div className="progress">
            <div className="progress-bar">
              <div className="progress-shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
